<template>
    <div class="w-100 position-relative">
        <div v-if="detailUserItem.estimateUseInfo"
             class="d-flex position-absolute"
             style="top: -3.8rem; right: 4rem;">
            <div>
                <h5>
                    <strong>
                        Total:
                        <span class="text-success">{{detailUserItem.estimateUseInfo.balance | numFormat('0,0[.][0000]')}}</span>
                        <img :src="$appPointIcon"
                             width="13" height="13" class="ml-1"
                             style="margin-top: -4px;">
                    </strong>
                </h5>
            </div>


            <div class="ml-4">
                <h5>
                    <strong>
                        Estimate:
                        <span class="text-success">{{detailUserItem.estimateUseInfo.temp_money | numFormat('0,0[.][0000]')}}</span>
                        <img :src="$appPointIcon"
                             width="13" height="13" class="ml-1"
                             style="margin-top: -4px;">
                    </strong>
                </h5>
            </div>

            <div class="ml-4">
                <h5>
                    <strong>
                        Remaining:
                        <span class="text-orange">{{detailUserItem.estimateUseInfo.remain_money | numFormat('0,0[.][0000]')}}</span>
                        <img :src="$appPointIcon"
                             width="13" height="13" class="ml-1"
                             style="margin-top: -4px;">
                    </strong>
                </h5>
            </div>

            <!--<div class="ml-4">
                {{detailUserItem.estimateUseInfo.estimated_shutdown_time | pretyDateUtc}}
            </div>-->

            <div v-if="detailUserItem.estimateUseInfo.warning"
                 class="ml-4">
                <h5 class="blink_me"><i class="el-icon-warning text-danger"></i> <strong>WARNING</strong></h5>
            </div>
        </div>
        <VuePerfectScrollbar class="w-100" style="height: calc(100dvh - 11rem)">
            <div class="flex-fill d-block d-lg-flex position-relative w-100" v-loading="isLoading">
                <div v-if="$root.windowWidth < 1000" class="text-nowrap text-center w-100">
                    <el-radio-group v-model="mobileDataShow" size="small" class="m-auto w-100">
                        <el-radio-button label="userInfo">User infomation</el-radio-button>
                        <el-radio-button label="tabInfo">Detail infomation</el-radio-button>
                    </el-radio-group>
                </div>

                <div class="d-block d-lg-flex" :style="`max-width: 100%; ${$root.windowWidth >= 1000 ? 'width: 25rem;' : ''}`"
                     v-if="$root.windowWidth >= 1000 || mobileDataShow === 'userInfo'">
                    <div class="d-flex flex-column w-100">
                        <!-- Profile Image -->
                        <input type="text"
                               class="position-fixed top-0 left-0"
                               style="display: none; color: #fff; z-index: 99999; opacity: 0"
                               id="userDetailCopy" />
                        <div class="card card-primary card-outline">
                            <div class="card-body box-profile">
                                <div class="d-flex align-items-center justify-content-center">
                                    <div>
                                        <div class="text-center">
                                            <div v-if="detailUserItem.profilePhoto !== null && detailUserItem.profilePhoto !== ''"
                                                 v-bind:class="{ 'deactive-account' : (detailUserItem.status === 3) }">
                                                <div v-if="!detailUserItem.profilePhoto.includes('http')">
                                                    <img v-if="!detailUserItem.profilePhoto.includes('data:image')"
                                                         class="profile-user-img img-fluid img-circle"
                                                         style="width: 4rem"
                                                         :src="`data:image/jpeg;base64,${detailUserItem.profilePhoto}`"
                                                         onerror="this.src='/img/avatars/male.png'" />
                                                    <img v-else
                                                         style="width: 4rem"
                                                         class="profile-user-img img-fluid img-circle"
                                                         :src="detailUserItem.profilePhoto"
                                                         onerror="this.src='/img/avatars/male.png'" />
                                                </div>
                                                <img v-else
                                                     style="width: 4rem"
                                                     class="profile-user-img img-fluid img-circle"
                                                     :src="detailUserItem.profilePhoto"
                                                     onerror="this.src='/img/avatars/male.png'" />
                                            </div>
                                            <div v-else>
                                                <img class="profile-user-img img-fluid img-circle"
                                                     src="/img/avatars/male.png"
                                                     style="width: 4rem" />
                                            </div>
                                        </div>
                                        <h3 class="profile-username text-center mb-0">
                                            <strong>{{ detailUserItem.fullName }}</strong>
                                        </h3>
                                        <div v-if="detailUserItem.partnerInfo">
                                            <span class="label bg-gradient-danger pb-1">
                                                <span>{{detailUserItem.partnerInfo.PartnerName}}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mb-2" v-if="detailUserItem.status === 2">
                                    <strong class="text-danger">This account has been locked</strong>
                                </div>
                                <div class="text-center mb-2" v-if="detailUserItem.status === 3">
                                    <strong class="text-orange">This account has been deactived</strong>
                                </div>
                                <div v-else class="text-center mb-2"></div>
                                <ul class="list-group list-group-unbordered mb-3">
                                    <li class="list-group-item pt-1 pb-1" v-if="userReferal !== null">
                                        <b>Referal from</b>&nbsp;
                                        <div class="float-right">
                                            <strong class="text-info">{{ userReferal.fullName }}</strong>
                                            ({{ userReferal.userName }})
                                            <button type="button"
                                                    class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1"
                                                    data-toggle="tooltip"
                                                    title="Click copy to clipboard"
                                                    @click="copyToClipboard('#userDetailCopy', userReferal.userName)"
                                                    style="font-size: 10px; position: relative; top: -2px">
                                                <i class="fa fa-copy text-muted"></i>
                                            </button>
                                        </div>
                                    </li>
                                    <li class="list-group-item pt-1 pb-1">
                                        <b>ID</b>
                                        <span class="float-right">
                                            {{ detailUserItem.id }}
                                            <button type="button"
                                                    class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1"
                                                    data-toggle="tooltip"
                                                    title="Click copy to clipboard"
                                                    @click="copyToClipboard('#userDetailCopy', detailUserItem.id)"
                                                    style="font-size: 10px; position: relative; top: -2px">
                                                <i class="fa fa-copy text-muted"></i>
                                            </button>
                                        </span>
                                    </li>
                                    <li class="list-group-item pt-1 pb-1">
                                        <b>Email</b>
                                        <a :href="isVisibleEmail(detailUserItem.staffTakeCare) ? `mail:${detailUserItem.email}` : ''" class="float-right">
                                            {{processEmailUser(detailUserItem.staffTakeCare, detailUserItem.email)}}
                                        </a>
                                    </li>
                                    <li class="list-group-item pt-1 pb-1">
                                        <b>Phone number</b>
                                        <a :href="isVisiblePhone(detailUserItem.staffTakeCare) ? `tel:${detailUserItem.phone}` : ''" class="float-right">
                                            {{processUserPhone(detailUserItem.staffTakeCare, detailUserItem.phone)}}
                                        </a>
                                    </li>
                                    <li class="list-group-item pt-1 pb-1">
                                        <b>Register at</b>
                                        <a class="float-right">{{ detailUserItem.dateCreatedAt }}</a>
                                    </li>
                                    <li class="list-group-item pt-1 pb-1">
                                        <b>Main balance</b>&nbsp;
                                        <a class="float-right">
                                            <strong class="text-info">
                                                {{detailUserItem.gpuHubAccountBalance | numFormat('0,0[.][0000]')}}
                                            </strong>
                                            &nbsp;
                                            <img :src="$appPointIcon" width="16" height="16" class="ml-1 position-relative" style="top: -3px;">
                                        </a>
                                    </li>
                                    <li class="list-group-item pt-1 pb-1">
                                        <b>Extra balance</b>&nbsp;
                                        <a class="float-right">
                                            <strong class="text-info">
                                                {{detailUserItem.gpuHubExtraBalance | numFormat('0,0[.][0000]')}}
                                            </strong>
                                            &nbsp;
                                            <img :src="$appPointIcon" width="16" height="16" class="ml-1 position-relative" style="top: -3px;">
                                        </a>
                                    </li>
                                    <li class="list-group-item pt-1 pb-1">
                                        <b>Source register</b>&nbsp;
                                        <a class="float-right">
                                            <strong class="text-info">
                                                {{detailUserItem.nationality}}
                                            </strong>
                                        </a>
                                    </li>
                                    <li class="list-group-item pt-1 pb-1"
                                        v-if="detailUserItem.gpuHubSyncVersionName">
                                        <b>App Sync</b>&nbsp;
                                        <a class="float-right">
                                            <strong class="text-info">
                                                {{detailUserItem.gpuHubSyncVersionName}}
                                            </strong>
                                        </a>
                                        <span v-if="detailUserItem.networkType">{{ detailUserItem.networkTypeName }}</span>
                                    </li>
                                    <li class="list-group-item pt-1 pb-1"
                                        v-if="detailUserItem.networkTypeName">
                                        <b>Network type</b>&nbsp;
                                        <a class="float-right">
                                            <strong class="text-info">
                                                {{detailUserItem.networkTypeName}}
                                            </strong>
                                        </a>
                                    </li>
                                    <li class="list-group-item pt-1 pb-1 text-nowrap"
                                        v-if="detailUserItem.uploadSpeed && detailUserItem.downloadSpeed">
                                        <b>Network speed</b>
                                        <span class="float-right">
                                            <el-tooltip content="Upload speed" placement="bottom" effect="light">
                                                <span class="text-blue"><i class="el-icon-upload2"></i> {{detailUserItem.uploadSpeed}} Mbps</span>
                                            </el-tooltip> /
                                            <el-tooltip content="Download speed" placement="bottom" effect="light">
                                                <span class="text-success"><i class="el-icon-download"></i> {{detailUserItem.downloadSpeed}} Mbps</span>
                                            </el-tooltip>

                                        </span>
                                    </li>
                                    <li class="list-group-item d-flex pt-1 pb-1">
                                        <div class="flex-fill">
                                            <span class="label bg-gradient-orange ml-1 pb-1"
                                                  v-if="detailUserItem.isSystemUser">
                                                <small><strong>System</strong></small>
                                            </span>
                                            <span class="label bg-gradient-success ml-1 pb-1"
                                                  v-if="toggleIsPriority">
                                                <small><strong>Priority</strong></small>
                                            </span>
                                            <span class="text-lowercase label bg-gradient-info ml-1 pb-1">
                                                <small><strong class="text-capitalize">{{ detailUserItem.userRole }}</strong></small>
                                            </span>
                                            <span v-if="detailUserItem.appCode"
                                                  :class="`ml-1 label ${$appCode[detailUserItem.appCode]}  pb-1`">
                                                <small><strong>{{ detailUserItem.appCode }}</strong></small>
                                            </span>
                                            <span v-if="detailUserItem.totaL_RECHARGE_BAL2 > 0"
                                                  class="ml-1 label bg-gradient-primary  pb-1">
                                                <small><strong>Paid</strong></small>
                                            </span>
                                            <span v-if="detailUserItem.totaL_RECHARGE_BAL2 >= $primeUserMinAmount"
                                                  class="ml-1 label bg-gradient-navy  pb-1">
                                                <small><strong>Prime</strong></small>
                                            </span>
                                        </div>

                                        <div v-if="rolesInfo.includes('OWNED') || rolesInfo.includes('QA')">
                                            <el-tooltip content="Make user priority" placement="top">
                                                <el-switch v-model="toggleIsPriority" @change="changeUserPrioriry"></el-switch>
                                            </el-tooltip>
                                        </div>
                                    </li>
                                </ul>
                                <div class="alert alert-warning cursor-pointer user-note-content p-2"
                                     v-if="detailUserItem.noteContent">
                                    <div class="d-flex">
                                        <div class="mr-2">
                                            <v-avatar size="2.5rem">
                                                <v-img id="app-avatar"
                                                       :src="`/img/avatars/${detailUserItem.staffSupport}.jpg`"
                                                       onerror="this.src='/img/avatar5.png'" />
                                            </v-avatar>
                                        </div>
                                        <div v-html="detailUserItem.noteContent"></div>
                                    </div>
                                </div>
                                <div class="mt-2 d-flex w-100">
                                    <el-button type="primary"
                                               v-if="checkAccess('USER_RECHARGE')"
                                               @click="toogleRecharge = !toogleRecharge"
                                               :class="`btn btn-sm bg-gradient-blue mr-1`">
                                        <b> <i class="fas fa-comment-dollar"></i> Recharge </b>
                                    </el-button> 
                                    <div class="flex-fill"></div>
                                    <el-dropdown :hide-timeout="500" type="button" v-if="checkAccess('USER_ACTION_CHANGE_LEVEL')">
                                        <el-button type="primary"
                                                   :class="`btn btn-sm bg-gradient-blue mr-1`">
                                            <el-tooltip effect="light"
                                                        content="User Level"
                                                        placement="top">
                                                <i class="fas fa-crown"></i>
                                            </el-tooltip>
                                        </el-button>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item :key="levelItem.id" v-for="(levelItem, indexItem) in $userConfiguaration">
                                                <button class="btn-block text-left" @click="setUserConfiguaration(levelItem.id)">
                                                    <strong>{{indexItem+1}}</strong>. {{levelItem.labelName}} (<strong class="text-primary">{{getOperatorName(levelItem.safeNameOperator)}} ${{levelItem.limitValue}}</strong>)
                                                </button>
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                    <el-button type="primary"
                                               :disabled="!allowResync"
                                               :class="`btn btn-sm bg-gradient-blue mr-1 ml-0`"
                                               v-if="checkAccess('USER_ACTION_RESYNC')"
                                               @click="resyncUserData">
                                        <el-tooltip effect="light"
                                                    content="Re-sync"
                                                    placement="top">
                                            <i class="el-icon-refresh"></i>
                                        </el-tooltip>
                                    </el-button>
                                    <el-dropdown :hide-timeout="500" type="button">
                                        <el-button type="primary"
                                                   :class="`btn btn-sm bg-gradient-blue mr-1`">
                                            ...
                                        </el-button>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item v-if="(rolesInfo.includes('OWNED') || rolesInfo.includes('QA')) && !detailUserItem.isSystemUser">
                                                <button class="btn-block text-left"
                                                        @click="addToSystemUser">
                                                    <i class="el-icon-user"></i> Add to system user
                                                </button>
                                            </el-dropdown-item>
                                            <el-dropdown-item v-if="checkAccess('USER_ACTION_BALANCE_DEDUCTION')">
                                                <button class="btn-block text-left" @click="toogleBalanceDeduction = true">
                                                    <i class="el-icon-remove"></i> Balance deduction
                                                </button>
                                            </el-dropdown-item>
                                            <el-dropdown-item v-if="checkAccess('USER_ACTION_VERIFY_EMAIL')">
                                                <button class="btn-block text-left"
                                                        @click="verifyEmailManual">
                                                    <i class="el-icon-check"></i> Verify email manual
                                                </button>
                                            </el-dropdown-item>
                                            <el-dropdown-item>
                                                <button class="btn-block text-left"
                                                        @click="sendEmailManual">
                                                    <i class="el-icon-message"></i> Send verify email
                                                </button>
                                            </el-dropdown-item>
                                            <el-dropdown-item>
                                                <button class="btn-block text-left"
                                                        @click="sendEmailReactiveManual">
                                                    <i class="el-icon-message"></i> Send re-active email
                                                </button>
                                            </el-dropdown-item>
                                            <el-dropdown-item :disabled="!allowResync"
                                                              v-if="checkAccess('USER_ACTION_RESYNC')">
                                                <button class="btn-block text-left" @click="resyncUserData">
                                                    <i class="el-icon-refresh"></i> Re-sync
                                                </button>
                                            </el-dropdown-item>
                                            <el-dropdown-item v-if="checkAccess('USER_ACTION_BLOCK')">
                                                <button class="btn-block text-left"
                                                        @click="deactiveUserToggle">
                                                    <i :class="`el-icon-${detailUserItem.status === 1 ? 'lock' : 'unlock'}`"></i>
                                                    {{detailUserItem.status === 1 ? "Lock account" : "Unlock account" }}
                                                </button>
                                            </el-dropdown-item>
                                            <el-dropdown-item v-if="(rolesInfo.includes('OWNED') || rolesInfo.includes('QA')) && !detailUserItem.isSystemUser">
                                                <button class="btn-block text-left"
                                                        v-bind:class="{ 'text-danger' : detailUserItem.isAgencyEnabled }"
                                                        @click="enableAgency((!detailUserItem.isAgencyEnabled))">
                                                    <i class="el-icon-suitcase"></i> {{detailUserItem.isAgencyEnabled ? 'Disable' : 'Enable'}} agency account
                                                </button>
                                            </el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </div>
                            </div>
                            <!-- /.card-body -->
                        </div>
                        <!-- /.card -->
                        <!-- About Me Box -->
                        <div class="card card-primary flex-fill" v-if="!toogleRecharge && !toogleBalanceDeduction">
                            <div class="card-body pt-2 pb-2">
                                <strong>
                                    <i class="fas fa-map-marked mr-1 text-info"></i> Region
                                </strong>

                                <p class="text-muted mb-0">
                                    <img :src="`/img/countries/${detailUserItem.region}.png`"
                                         class="position-relative mr-1"
                                         onerror="this.style = 'display: none;';"
                                         style="top: -2px" />&nbsp;&nbsp;
                                    <span v-if="Object.keys($countryList).includes(detailUserItem.region)">{{ $countryList[detailUserItem.region] }}</span>
                                    <span v-else>{{ detailUserItem.region }}</span>
                                </p>

                                <div v-if="detailUserItem.address">
                                    <hr class="mt-1 mb-1" />
                                    <strong>
                                        <i class="fas fa-map-marker-alt mr-1 text-info"></i> Address
                                    </strong>
                                    <p class="text-muted mb-0">{{ detailUserItem.address }}</p>
                                </div>
                            </div>
                            <!-- /.card-body -->
                        </div>
                        <!-- /.card -->
                    </div>
                </div>
                <div class="flex-fill ml-0 ml-lg-3 d-flex" style="max-width: 100%;"
                     v-if="$root.windowWidth > 1000 ? true : (!toogleRecharge && !toogleBalanceDeduction && mobileDataShow === 'tabInfo')">
                    <div class="card card-primary card-tabs" style="width: 100%">
                        <div class="card-header p-0 pt-1">
                            <ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
                                <li class="nav-item" v-if="checkAccess('MACHINE_VIEW')">
                                    <a class="nav-link active"
                                       data-toggle="pill"
                                       href="#systemImage"
                                       role="tab"
                                       aria-controls="custom-tabs-two-home"
                                       aria-selected="true">
                                        <i class="fas fa-desktop"></i> <span class="d-none d-lg-inline-block">&nbsp;System Image</span>
                                    </a>
                                </li>
                                <li class="nav-item" v-if="checkAccess('GPUHUBBILLING_VIEW')">
                                    <a class="nav-link"
                                       data-toggle="pill"
                                       href="#user-billing"
                                       role="tab"
                                       aria-controls="custom-tabs-two-home"
                                       aria-selected="true">
                                        <i class="fas fa-file-invoice"></i> <span class="d-none d-lg-inline-block">&nbsp;Billings</span>
                                    </a>
                                </li>
                                <li class="nav-item"  v-if="checkAccess('TRANSACTION_VIEW')">
                                    <a class="nav-link"
                                       data-toggle="pill"
                                       href="#user-transactions"
                                       role="tab"
                                       aria-controls="custom-tabs-two-home"
                                       aria-selected="true">
                                        <i class="el-icon-bank-card"></i> <span class="d-none d-lg-inline-block">&nbsp;Transactions</span>
                                    </a>
                                </li>
                                <li class="nav-item" v-if="checkAccess('USERHISTORY_VIEW')">
                                    <a class="nav-link"
                                       data-toggle="pill"
                                       href="#userHistory"
                                       role="tab"
                                       aria-controls="custom-tabs-two-home"
                                       aria-selected="true">
                                        <i class="fas fa-history"></i> <span class="d-none d-lg-inline-block">&nbsp;Activities</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link"
                                       data-toggle="pill"
                                       href="#rating"
                                       role="tab"
                                       aria-controls="custom-tabs-two-home"
                                       aria-selected="true">
                                        <i class="el-icon-medal"></i> <span class="d-none d-lg-inline-block">&nbsp;Rating</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link"
                                       data-toggle="pill"
                                       href="#usersReferal"
                                       role="tab"
                                       aria-controls="custom-tabs-two-home"
                                       aria-selected="true">
                                        <i class="fas fa-users"></i> <span class="d-none d-lg-inline-block">&nbsp;Referal</span>
                                    </a>
                                </li>
                                <li class="nav-item" v-if="$licenseFeature">
                                    <a class="nav-link"
                                       data-toggle="pill"
                                       href="#licenseManagement"
                                       role="tab"
                                       aria-controls="custom-tabs-two-home"
                                       aria-selected="true">
                                        <i class="fas fa-certificate"></i> <span class="d-none d-lg-inline-block">&nbsp;License</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link"
                                       data-toggle="pill"
                                       href="#Settings"
                                       role="tab"
                                       aria-controls="custom-tabs-two-home"
                                       aria-selected="true">
                                        <i class="fas fa-cog"></i> <span class="d-none d-lg-inline-block">&nbsp;Settings</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link"
                                       data-toggle="pill"
                                       href="#userNoteTab"
                                       role="tab"
                                       aria-controls="custom-tabs-two-home"
                                       aria-selected="true">
                                        <i class="far fa-comment-dots"></i> <span class="d-none d-lg-inline-block">&nbsp;Note</span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link"
                                       data-toggle="pill"
                                       href="#chatHistory"
                                       role="tab"
                                       aria-controls="custom-tabs-two-home"
                                       aria-selected="true">
                                        <i class="far fa-comments"></i> <span class="d-none d-lg-inline-block">&nbsp;Chat history</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div class="card-body p-2">
                            <div class="tab-content" id="user-jobs">
                                <div class="tab-pane fade show active"
                                     id="systemImage"
                                     role="tabpanel"
                                     aria-labelledby="custom-tabs-two-home-tab">

                                    <listImageOfUser :cardHeight="`height: calc(100dvh - ${$root.windowWidth > 1000 ? 15 : 18 }rem); margin-bottom: 0;`"
                                                     :minInfo="true"
                                                     :ownedTarget="detailUserItem.id" />
                                </div>
                                <div class="tab-pane fade"
                                     id="user-billing"
                                     role="tabpanel"
                                     aria-labelledby="custom-tabs-two-home-tab">
                                    <listBillingOfUser :cardHeight="`height: calc(100dvh - ${$root.windowWidth > 1000 ? 15 : 18 }rem); margin-bottom: 0;`"
                                                       :minInfo="true"
                                                       :ownedTarget="detailUserItem.id" />
                                </div>
                                <div class="tab-pane fade"
                                     id="user-transactions"
                                     role="tabpanel"
                                     aria-labelledby="custom-tabs-two-home-tab">
                                    <listTransactionOfUser :cardHeight="`height: calc(100dvh - ${$root.windowWidth > 1000 ? 15 : 18 }rem); margin-bottom: 0;`"
                                                           :minInfo="true"
                                                           :ownedTarget="detailUserItem.id" />
                                </div>
                                <div class="tab-pane fade"
                                     id="userHistory"
                                     role="tabpanel"
                                     aria-labelledby="custom-tabs-two-home-tab">
                                    <userHistoryList :cardHeight="`height: calc(100dvh - ${$root.windowWidth > 1000 ? 15 : 18 }rem); margin-bottom: 0;`"
                                                     :minInfo="true"
                                                     :username="detailUserItem.userName" />
                                </div>
                                <div class="tab-pane fade"
                                     id="rating"
                                     role="tabpanel"
                                     aria-labelledby="custom-tabs-two-home-tab">
                                    <ratingComponent :cardHeight="`height: calc(100dvh - ${$root.windowWidth > 1000 ? 16 : 19 }rem); margin-bottom: 0;`"
                                                     :minInfo="true"
                                                     :detailUserItem="detailUserItem" />
                                </div>
                                <div class="tab-pane fade"
                                     id="usersReferal"
                                     role="tabpanel"
                                     aria-labelledby="custom-tabs-two-home-tab">
                                    <userReferalList :cardHeight="`height: calc(100dvh - ${$root.windowWidth > 1000 ? 15 : 18 }rem); margin-bottom: 0;`"
                                                     :minInfo="true"
                                                     :detailUserItem="detailUserItem" />
                                </div>
                                <div  v-if="$licenseFeature" class="tab-pane fade"
                                     id="licenseManagement"
                                     role="tabpanel" 
                                     aria-labelledby="custom-tabs-two-home-tab">
                                    <div class="w-100" v-loading="isUpdatingSetting">
                                        <div>
                                            <div class="row">
                                                <div class="col-12 col-md-6"
                                                     v-for="licenseItem in licenseSoftwares"
                                                     :key="licenseItem.id">
                                                    <table class="table table-striped table-bordered w-100 mb-1">
                                                        <tbody>
                                                            <tr v-if="checkAccess('USER_SETTING_LICENSE_SOFTWARE')"
                                                                class="text-navy">
                                                                <td class="v-center">
                                                                    <span class="ml-2"><strong class="">{{licenseItem.softwareName}} license</strong></span>
                                                                </td>
                                                                <td style="width: 1%;" class="text-nowrap text-center">
                                                                    <div>
                                                                        <el-switch v-model="licenseItem.allowedLicense" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr v-if="licenseItem.allowedLicense && checkAccess('USER_SETTING_LICENSE_SOFTWARE')">
                                                                <td class="v-center">
                                                                    <span class="ml-2">Number of <strong class="">{{licenseItem.softwareName}} license</strong> instance granted</span>
                                                                </td>
                                                                <td style="width: 1%;" class="text-nowrap text-center">
                                                                    <div>
                                                                        <el-input-number v-model="licenseItem.licenseQuantity"
                                                                                         :min="0" :max="100" style="width: 8rem;"></el-input-number>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div class="text-left mt-2"
                                              v-if="checkAccess('USER_SETTING_LICENSE_SOFTWARE')">

                                            <button class="btn btn-default mr-3"
                                                    @click="getLicenseInfo">
                                                <i class="fas fa-redo"></i> <strong class="ml-1">Reset</strong>
                                            </button>
                                            <button class="btn btn-primary"
                                                    @click="setLicenseInfo">
                                                <i class="far fa-save"></i> <strong class="ml-1">Flick off</strong>
                                            </button>
                                        </div>

                                        <div v-if="checkAccess('USER_SETTING_LICENSE_SOFTWARE')">
                                            <hr />
                                            <div class="d-flex align-items-center mt-0">
                                                <div class="flex-fill">
                                                    <h5 class="mt-0"><strong>Clients connected</strong></h5>
                                                </div>
                                                <div>
                                                    <el-button size="small" type="primary" 
                                                               style="position: relative;" round
                                                               @click="getLicenseConnected">
                                                        <i class="el-icon-refresh-left"></i> Refresh
                                                    </el-button>
                                                </div>
                                            </div>
                                            <div class="mb-4" v-if="licenseClientConnected.length > 0">
                                                <div class="row">

                                                    <div class="col-12 col-sm-4 mt-0" :key="clientItem.userId" v-for="clientItem in licenseClientConnected">
                                                        <el-card class="socket-client-card box-card p-0 pb-2">
                                                            <div class="mb-2" v-if="isNumeric(clientItem.licenseUsedType)">
                                                                <div v-if="clientItem.licenseUsedType === 2">
                                                                    <img class="mr-4" src="/img/OctaneRender.svg" alt="OctaneRender" style="height: 40px; ">
                                                                    <img src="/img/RedshiftRender.svg" alt="RedshiftRender" style="height: 40px; ">
                                                                </div>
                                                                <div v-else>
                                                                    <img :src="`/img/${clientItem.licenseUsedType === 0 ? 'RedshiftRender' : 'OctaneRender'}.svg`"
                                                                         :alt="`${clientItem.licenseUsedType === 0 ? 'RedshiftRender' : 'OctaneRender'}`"
                                                                         style="height: 40px;">
                                                                </div>
                                                            </div>
                                                            <div class="mb-2" v-else>
                                                                <div>
                                                                    <img v-if="clientItem.licenseUsedType.octane"
                                                                         class="mr-4"
                                                                         src="/img/OctaneRender.svg" alt="OctaneRender" style="height: 30px; ">
                                                                    <img v-if="clientItem.licenseUsedType.redshift"
                                                                         class="mr-4"
                                                                         src="/img/RedshiftRender.svg" alt="RedshiftRender" style="height: 30px; ">
                                                                    <span v-if="clientItem.licenseUsedType.redshift"
                                                                          class="mr-4 text-nowrap"
                                                                          style="font-size: 1.4rem; font-weight: 600;">Cinema 4D</span>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                <div class="flex-fill d-flex">
                                                                    <div class="flex-fill w-50">
                                                                        <div><strong>Machine ID:</strong> {{clientItem.vmMachineId}}</div>
                                                                        <div><strong>Computer:</strong> {{clientItem.computerName}}</div>
                                                                        <div><strong>IP Address:</strong> {{clientItem.ipAddress20}}</div>
                                                                        <!--<div><strong>Client ID:</strong> {{clientItem.userId}}</div>-->
                                                                    </div>
                                                                    <div class="flex-fill w-50">
                                                                        <div><strong>Public IP:</strong> {{clientItem.remoteAddress}}</div>
                                                                        <div><strong>MAC:</strong> {{clientItem.macAddress}}</div>
                                                                        <div><strong>Connected:</strong> {{clientItem.connected}}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="mt-2">
                                                                <el-button size="small" type="danger" @click="disconnectedLicenseClient(clientItem.userId)">
                                                                    <i class="el-icon-close"></i> Disconnect
                                                                </el-button>
                                                                <el-button size="small" type="primary" @click="checkLicenseClient(clientItem.userId)">
                                                                    <i class="el-icon-check"></i> Check is work fine
                                                                </el-button>
                                                            </div>
                                                        </el-card>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else
                                                 class="nodata-container mb-4 mt-3 card">
                                                <div class="align-self-center text-center">
                                                    <h4 class="mt-3 mb-1">
                                                        <strong class="text-muted">No clients are connected</strong>
                                                    </h4>
                                                    <p class="text-muted">All clients will be show here !</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade"
                                     id="Settings"
                                     role="tabpanel"
                                     aria-labelledby="custom-tabs-two-home-tab">
                                    <div>
                                        <div>
                                            <table class="table table-striped table-bordered w-100 mb-1">
                                                <tbody>
                                                    <tr>
                                                        <td class="v-center">
                                                            <span class="ml-2">Allow copy <strong>Z:</strong> to <strong>Image C:</strong> through  <strong class="">iRender Drive</strong></span>
                                                        </td>
                                                        <td style="width: 1%;" class="text-nowrap text-center">
                                                            <div>
                                                                <el-switch :disabled="!(rolesInfo.includes('OWNED') || rolesInfo.includes('QA'))"
                                                                           v-model="settings.iDriveSettings.allowedZtoImageC" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-right mt-4"
                                              v-if="(rolesInfo.includes('OWNED') || rolesInfo.includes('QA'))">
                                            <button class="btn btn-primary"
                                                    @click="saveSettingsUsers">
                                                <i class="far fa-save"></i> <strong class="ml-1">Save changed</strong>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade"
                                     id="userNoteTab"
                                     role="tabpanel"
                                     aria-labelledby="custom-tabs-two-home-tab"> 
                                    <comments :detailItem="detailUserItem"
                                        module="user" />
                                </div>
                                <div class="tab-pane fade"
                                     id="chatHistory"
                                     role="tabpanel"
                                     aria-labelledby="custom-tabs-two-home-tab"> 
                                    <chatwootHistoryView :detailUserItem="detailUserItem"
                                                         :style="`height: calc(100dvh - ${$root.windowWidth > 1000 ? 15 : 18 }rem); margin-bottom: 0;`"/>
                                </div>
                            </div>
                        </div>
                        <!-- /.card -->
                    </div>
                </div>

                <div v-if="toogleRecharge"
                     class="position-absolute w-100 h-100 d-flex"
                     style="background-color: rgba(0, 0, 0, 0.6); z-index: 99; top: 0; left: 0;">
                    <div style="width: 45rem; max-width: 100%; margin: auto">
                        <userRechargeBalance v-if="detailUserItem"
                                             :detailUserItem="detailUserItem"
                                             @closeRecharge="toogleRecharge = false" />
                    </div>
                </div>

                <div v-if="toogleBalanceDeduction"
                     class="position-absolute w-100 h-100 d-flex p-2"
                     style="background-color: rgba(0, 0, 0, 0.6); z-index: 99; top: 0; left: 0;">
                    <div style="width: 40rem; max-width: 100%; margin: auto" class="bg-white">
                        <userBalanceDeduction v-if="detailUserItem"
                                              :detailUserItem="detailUserItem"
                                              @closeModal="toogleBalanceDeduction = false" />
                    </div>
                </div>
            </div>
        </VuePerfectScrollbar>
    </div>
</template>

<script>
    import lazyLoadComponent from "@/scripts/lazyLoadComponent";
    import SkeletonBox from "@/components/SkeletonBox";
    import usersApi from "@/api/common/users";
    import agencyApi from "@/api/common/agencyApi";
    import commonAct from "@/commonActionHandle.js";
    import baseComponent from "@/scripts/baseComponent";
    import { mapState } from "vuex";
    import { providerSelection } from "@/constant/config";
    import { getlazyLoadInfo } from "@/scripts/_helpers/userHelper";
    export default {
        extends: baseComponent,
        components: {
            listImageOfUser: lazyLoadComponent({
                componentFactory: () => import("@/views/gpuHub/vmMachines/vmMachine"),
                loading: SkeletonBox,
            }),
            listBillingOfUser: lazyLoadComponent({
                componentFactory: () => import("@/views/gpuHub/billing/billing"),
                loading: SkeletonBox,
            }),
            listTransactionOfUser: lazyLoadComponent({
                componentFactory: () =>
                    import("@/views/gpuHub/transactions/transactions"),
                loading: SkeletonBox,
            }),
            userHistoryList: lazyLoadComponent({
                componentFactory: () => import("@/views/users/UsersHistory"),
                loading: SkeletonBox,
            }),
            userReferalList: lazyLoadComponent({
                componentFactory: () => import("@/views/users/ListReferalUsers"),
                loading: SkeletonBox,
            }),
            ratingComponent: lazyLoadComponent({
                componentFactory: () => import("@/views/Rating/Rating"),
                loading: SkeletonBox,
            }),
            userRechargeBalance: lazyLoadComponent({
                componentFactory: () => import("@/views/users/UserRechargeBalance"),
                loading: SkeletonBox,
            }),
            userBalanceDeduction: lazyLoadComponent({
                componentFactory: () => import("@/views/users/UserBalanceDeduction"),
                loading: SkeletonBox,
            }),
            comments: lazyLoadComponent({
                componentFactory: () => import("@/components/Comments"),
                loading: SkeletonBox,
            }),
            chatwootHistoryView: lazyLoadComponent({
                componentFactory: () => import("@/views/users/ChatwootHistoryView"),
                loading: SkeletonBox,
            }),
            //contactInfomations: lazyLoadComponent({
            //    componentFactory: () => import("@/views/users/_UserMoreContactsInfomations"),
            //    loading: SkeletonBox,
            //}),
        },
        props: {
            detailUserItem: {
                type: Object,
                default: null,
            },
        },
        data() {
            return {
                toogleRecharge: false,
                toogleBalanceDeduction: false,
                toggleIsPriority: false,
                mobileDataShow: 'userInfo',
                rechargeModel: {
                    pickedSource: "gpuHub",
                    amount: 0.0,
                    pointConversion: 0.0,
                    extraPointConversion: 0.0,
                    provider: "TESTING",
                    userId: this.detailUserItem.id,
                },
                providerSelection: providerSelection,
                userReferal: null,
                allowResync: true,
                allowedUseLicense: false,
                allowedUseLicenseQuantity: 1,
                licenseSoftwares: [],
                isUpdatingSetting: false,
                licenseClientConnected: [],
                intervalLoadClient: null,
                settings: {
                    iDriveSettings: {
                        allowedZtoImageC: false
                    }
                }
            };
        },
        watch: {
            detailUserItem(newVal) {
                if (this.detailUserItem !== null) {
                    this.getUserReferal();
                    this.addRefundSelection();
                    this.getLicenseInfo();
                    this.getLicenseConnected();
                    this.getUserDetail();
                    this.getEstimateUse();
                }
            },
        },
        computed: {
            ...mapState({
                userInfo: (state) => state.auth.user,
                rolesInfo: (state) => state.auth.roles,
                staffsInfo: (state) => state.staffs,
                lowBalanceUser: (state) => state.lowBalanceUser,
                $emailAllowedChangeSource: (state) => state.emailAllowedChangeSource,
                $userConfiguaration: (state) => state.userConfiguaration,
            }),
        },
        mounted() {
            this.getUserReferal();
            this.addRefundSelection();
            this.getLicenseInfo();
            this.getLicenseConnected();
            this.getUserDetail();
            this.getEstimateUse();
            this.intervalLoadClient = setInterval(() => {
                this.getLicenseConnected();
                this.getEstimateUse();
            }, 3000);
        },
        beforeDestroy() {
            clearInterval(this.intervalLoadClient);
            this.intervalLoadClient = null;
        },
        methods: {
            addRefundSelection() {
                //let findIndex = this.providerSelection.findIndex(
                //  (x) => x.id === "REFUND"
                //);

                //if (findIndex < 0) {
                //  this.providerSelection = this.providerSelection.concat(
                //    this.$emailAllowedChangeSource.includes(this.userInfo.userEmail)
                //      ? [{ id: "REFUND", text: "REFUND" }]
                //      : []
                //  );
                //}
            },
            getUserReferal() {
                usersApi
                    .getUserReferal(this.detailUserItem.id)
                    .then((response) => {
                        if (response.data && response.data.result === 0) {
                            this.$set(this, "userReferal", response.data.data);
                        } else {
                            if (
                                response.data.message !== null &&
                                response.data.message !== ""
                            ) {
                                console.error(response.data.message || this.$lang.common.error);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            getUserDetail() {
                usersApi
                    .getUserDetail(this.detailUserItem.id)
                    .then((response) => {
                        if (response.data && response.data.result === 0) {
                            //console.log("getUserDetail", response.data.data);
                            this.detailUserItem.isAgencyEnabled = response.data.data.isAgencyEnabled;
                            this.detailUserItem.isPriority = response.data.data.isPriority;
                            this.toggleIsPriority = response.data.data.isPriority;
                            this.detailUserItem.gpuHubSyncVersion = response.data.data.gpuHubSyncVersion;
                            this.detailUserItem.gpuHubSyncVersionName = response.data.data.gpuHubSyncVersionName;
                            this.detailUserItem.networkType = response.data.data.networkType;
                            this.detailUserItem.networkTypeName = response.data.data.networkTypeName;
                            this.detailUserItem.isAllowedZtoC = response.data.data.isAllowedZtoC;
                            this.settings.iDriveSettings.allowedZtoImageC = response.data.data.isAllowedZtoC;
                        } else {
                            if (response.data.message !== null && response.data.message !== "") {
                                console.error(response.data.message || this.$lang.common.error);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
                getlazyLoadInfo(this, this.detailUserItem, this.detailUserItem.id);
            },
            getEstimateUse() {
                usersApi.getEstimateUse(this.detailUserItem.id)
                    .then((response) => {
                        if (response.data && response.data.result === 0) {
                            this.detailUserItem.estimateUseInfo = response.data.data;
                        } else {
                            if (response.data.message !== null && response.data.message !== "") {
                                console.error(response.data.message || this.$lang.common.error);
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            executeRechargeBalance(amount, pointConversion, extraPointConversion, provider, pickedSource) {
                commonAct.showConfirm(
                    `Recharge account balance for user <strong>${this.detailUserItem.fullName}</strong>,
                                        Amount <strong>$${amount}</strong>
                                        Main balance point <strong>${pointConversion}${this.$pointNameShort}</strong>,
                                        Extra balance point <strong>${extraPointConversion}${this.$pointNameShort}</strong>,
                                        Provider: <strong>${provider} ?`,
                    () => {
                        this.showLoading();
                        this.rechargeModel.provider = provider;
                        this.rechargeModel.amount = amount;
                        this.rechargeModel.pointConversion = pointConversion;
                        this.rechargeModel.extraPointConversion = extraPointConversion;
                        this.rechargeModel.pickedSource = pickedSource;
                        this.rechargeModel.userId = this.detailUserItem.id;
                        this.rechargeModel.deviceInformation = JSON.stringify(
                            this.getInformationClient()
                        );
                        usersApi
                            .rechargeBalance(this.rechargeModel)
                            .then((response) => {
                                if (response.data && response.data.result === 0) {
                                    this.hideLoading();
                                    this.showSuccessToast(
                                        "Recharge account balance has been success"
                                    );
                                    if (this.rechargeModel.pickedSource === "gpuHub") {
                                        this.detailUserItem.gpuHubAccountBalance += this.rechargeModel.pointConversion;
                                        this.detailUserItem.gpuHubAccountBalance += this.rechargeModel.extraPointConversion;
                                    }
                                } else {
                                    this.hideLoading();
                                    if (
                                        response.data.message !== null &&
                                        response.data.message !== ""
                                    ) {
                                        commonAct.showError(
                                            response.data.message || this.$lang.common.error
                                        );
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                this.hideLoading();
                                commonAct.showError(error);
                            });
                    }
                );
            },
            rechargeBalance() {
                this.executeRechargeBalance(
                    this.rechargeModel.amount,
                    this.rechargeModel.pointConversion,
                    this.rechargeModel.extraPointConversion,
                    this.rechargeModel.provider,
                    "gpuHub"
                );
            },
            updatePoint(val) {
                this.$set(this.rechargeModel, "pointConversion", val);
            },
            rechargeBalanceForTest() {
                this.executeRechargeBalance(0, 0, 5, "TESTING", "gpuHub");
            },
            deactiveUserToggle() {
                this.isUpdatingSetting = true;
                commonAct.showConfirm(
                    `${this.detailUserItem.status === 1 ? "Lock" : "Unlock"} user <strong>${this.detailUserItem.fullName
                    }</strong> ?`,
                    () => {
                        let request = {
                            isDeactive: this.detailUserItem.status === 1,
                            userId: this.detailUserItem.id,
                            deviceInformation: JSON.stringify(this.getInformationClient()),
                        };
                        usersApi
                            .deactiveUser(request)
                            .then((response) => {
                                this.isUpdatingSetting = false;
                                if (response.data && response.data.result === 0) {
                                    this.showSuccessToast("User info has been updated");
                                    this.$set(
                                        this.detailUserItem,
                                        "status",
                                        this.detailUserItem.status === 1 ? 2 : 1
                                    );
                                } else {
                                    if (
                                        response.data.message !== null &&
                                        response.data.message !== ""
                                    ) {
                                        commonAct.showError(
                                            response.data.message || this.$lang.common.error
                                        );
                                    }
                                }
                            })
                            .catch((error) => {
                                this.isUpdatingSetting = false;
                                console.error(error);
                                commonAct.showError(error);
                            });
                    }
                );
            },
            addToSystemUser() {
                commonAct.showConfirm(
                    `Add user <strong>${this.detailUserItem.fullName}</strong> to System user list ?`,
                    () => {
                        let request = {
                            userId: this.detailUserItem.id,
                            deviceInformation: JSON.stringify(this.getInformationClient()),
                        };
                        usersApi
                            .addtoSystemUser(request)
                            .then((response) => {
                                if (response.data && response.data.result === 0) {
                                    this.showSuccessToast("User info has been updated");
                                    this.$set(this.detailUserItem, "isSystemUser", true);
                                } else {
                                    if (
                                        response.data.message !== null &&
                                        response.data.message !== ""
                                    ) {
                                        commonAct.showError(
                                            response.data.message || this.$lang.common.error
                                        );
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                commonAct.showError(error);
                            });
                    }
                );
            },
            verifyEmailManual() {
                commonAct.showConfirm(
                    `Verify Email Manual user <strong>${this.detailUserItem.fullName}</strong> ?`,
                    () => {
                        let request = {
                            userId: this.detailUserItem.id,
                            deviceInformation: JSON.stringify(this.getInformationClient()),
                        };
                        usersApi
                            .verifyEmailManual(request)
                            .then((response) => {
                                if (response.data && response.data.result === 0) {
                                    this.showSuccessToast("User info has been updated");
                                    this.$set(this.detailUserItem, "emailVerified", true);
                                } else {
                                    if (
                                        response.data.message !== null &&
                                        response.data.message !== ""
                                    ) {
                                        commonAct.showError(
                                            response.data.message || this.$lang.common.error
                                        );
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                commonAct.showError(error);
                            });
                    }
                );
            },
            sendEmailManual() {
                commonAct.showConfirm(
                    `Verify Email Manual user <strong>${this.detailUserItem.fullName}</strong> ?`,
                    () => {
                        let request = {
                            userId: this.detailUserItem.id,
                            deviceInformation: JSON.stringify(this.getInformationClient()),
                        };
                        usersApi
                            .sendEmailManual(request)
                            .then((response) => {
                                if (response.data && response.data.result === 0) {
                                    this.showSuccessToast("User info has been updated");
                                    // this.$set(this.detailUserItem, "emailVerified", true);
                                } else {
                                    if (
                                        response.data.message !== null &&
                                        response.data.message !== ""
                                    ) {
                                        commonAct.showError(
                                            response.data.message || this.$lang.common.error
                                        );
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                commonAct.showError(error);
                            });
                    }
                );
            },
            sendEmailReactiveManual(){
                commonAct.showConfirm(
                    `Resend re-active email to user <strong>${this.detailUserItem.fullName}</strong> ?`,
                    () => {
                        let request = {
                            userId: this.detailUserItem.id,
                            deviceInformation: JSON.stringify(this.getInformationClient()),
                        };
                        usersApi
                            .sendEmailReactiveManual(request)
                            .then((response) => {
                                if (response.data && response.data.result === 0) {
                                    this.showSuccessToast("User info has been updated");
                                    // this.$set(this.detailUserItem, "emailVerified", true);
                                } else {
                                    if (
                                        response.data.message !== null &&
                                        response.data.message !== ""
                                    ) {
                                        commonAct.showError(
                                            response.data.message || this.$lang.common.error
                                        );
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                commonAct.showError(error);
                            });
                    }
                );
            },
            resyncUserData() {
                let request = {
                    userId: this.detailUserItem.id,
                    deviceInformation: JSON.stringify(this.getInformationClient()),
                };
                usersApi
                    .resyncUserData(request)
                    .then((response) => {
                        if (response.data && response.data.result === 0) {
                            this.showSuccessToast("Your request has been proccessing");
                            this.allowResync = false;
                            setTimeout(() => {
                                this.allowResync = true;
                            }, 10000);
                        } else {
                            if (
                                response.data.message !== null &&
                                response.data.message !== ""
                            ) {
                                commonAct.showError(
                                    response.data.message || this.$lang.common.error
                                );
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        commonAct.showError(error);
                    });
            },
            getLicenseInfo() {
                usersApi
                    .getLicenseInfo(this.detailUserItem.id)
                    .then((response) => {
                        if (response.data && response.data.result === 0) {
                            this.$set(this, "licenseSoftwares", response.data.data);
                        } else {
                            this.hideLoading();
                            if (
                                response.data.message !== null &&
                                response.data.message !== ""
                            ) {
                                commonAct.showError(
                                    response.data.message || this.$lang.common.error
                                );
                            }
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        this.hideLoading();
                        commonAct.showError(error);
                    });
            },
            getLicenseConnected() {
                usersApi
                    .getLicenseConnected(this.detailUserItem.id)
                    .then((response) => {
                        if (response.data && response.data.result === 0) {
                            this.$set(this, "licenseClientConnected", response.data.data);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            setLicenseInfo() {
                this.isUpdatingSetting = true;
                let request = {
                    userId: this.detailUserItem.id,
                    licenseItem: this.licenseSoftwares,
                    deviceInformation: JSON.stringify(this.getInformationClient()),
                };
                usersApi
                    .setLicenseInfo(request)
                    .then((response) => {
                        this.isUpdatingSetting = false;
                        if (response.data && response.data.result === 0) {
                            this.showSuccessToast("User info has been updated");
                            this.$set(this, "licenseSoftwares", response.data.data);
                        } else {
                            this.getLicenseInfo();
                            if (
                                response.data.message !== null &&
                                response.data.message !== ""
                            ) {
                                commonAct.showError(
                                    response.data.message || this.$lang.common.error
                                );
                            }
                        }
                    })
                    .catch((error) => {
                        this.isUpdatingSetting = false;
                        this.$set(this, "allowedUseLicense", !this.allowedUseLicense);
                        console.error(error);
                        commonAct.showError(error);
                    });

            },
            disconnectedLicenseClient(socketClientId) {
                this.isUpdatingSetting = true;
                let request = {
                    userId: socketClientId,
                    deviceInformation: JSON.stringify(this.getInformationClient()),
                };
                usersApi
                    .disconnectedLicenseClient(request)
                    .then((response) => {
                        this.isUpdatingSetting = false;
                        if (response.data && response.data.result === 0) {
                            this.showSuccessToast(`Client ${socketClientId} disconnected`);
                            this.getLicenseConnected();
                        } else {
                            if (
                                response.data.message !== null &&
                                response.data.message !== ""
                            ) {
                                commonAct.showError(
                                    response.data.message || this.$lang.common.error
                                );
                            }
                        }
                        this.getLicenseConnected();
                    })
                    .catch((error) => {
                        this.isUpdatingSetting = false;
                        this.$set(this, "allowedUseLicense", !this.allowedUseLicense);
                        console.error(error);
                        commonAct.showError(error);
                    });
            },
            checkLicenseClient(socketClientId) {
                this.isUpdatingSetting = true;
                let request = {
                    userId: socketClientId,
                    deviceInformation: JSON.stringify(this.getInformationClient()),
                };
                usersApi
                    .checkLicenseClient(request)
                    .then((response) => {
                        this.isUpdatingSetting = false;
                        if (response.data && response.data.result === 0) {
                            var parseValue = JSON.parse(response.data.data);
                            if (parseValue.Value) this.showSuccessToast("All settings license work fine");
                            else this.showDangerToast("Some settings license not working well");
                        } else {
                            if (
                                response.data.message !== null &&
                                response.data.message !== ""
                            ) {
                                commonAct.showError(
                                    response.data.message || this.$lang.common.error
                                );
                            }
                        }
                        this.getLicenseConnected();
                    })
                    .catch((error) => {
                        this.isUpdatingSetting = false;
                        console.error(error);
                        commonAct.showError(error);
                    });
            },
            getOperatorName(op) {
                switch (op) {
                    case '>=':
                        return '≥';
                    case '<=':
                        return '≤';
                    default:
                        return op;
                }
            },
            setUserConfiguaration(levelId) {
                let request = {
                    userId: this.detailUserItem.id,
                    levelId: levelId,
                    deviceInformation: JSON.stringify(this.getInformationClient()),
                };
                usersApi.setUserConfiguaration(request)
                    .then((response) => {
                        if (response.data && response.data.result === 0) {
                            this.showSuccessToast("User info has been updated");
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            changeUserPrioriry() {
                let request = {
                    userId: this.detailUserItem.id,
                    data: this.toggleIsPriority,
                    deviceInformation: JSON.stringify(this.getInformationClient()),
                };
                usersApi.changeUserPrioriry(request)
                    .then((response) => {
                        if (response.data && response.data.result === 0) {
                            this.showSuccessToast("User info has been updated");
                        }
                        else {
                            this.toggleIsPriority = !this.toggleIsPriority;
                        }
                    })
                    .catch((error) => {
                        this.toggleIsPriority = !this.toggleIsPriority;
                        console.error(error);
                    });
            },
            enableAgency(isEnabled) {
                commonAct.showConfirm(
                    `Enable agency account user <strong>${this.detailUserItem.fullName}</strong> ?`,
                    () => {
                        let request = {
                            userId: this.detailUserItem.id,
                            isEnabled: isEnabled,
                            deviceInformation: JSON.stringify(this.getInformationClient()),
                        };
                        agencyApi
                            .enableAgency(request)
                            .then((response) => {
                                if (response.data && response.data.result === 0) {
                                    this.showSuccessToast("User info has been updated");
                                    this.$set(this.detailUserItem, "isAgencyEnabled", isEnabled);
                                } else {
                                    if (
                                        response.data.message !== null &&
                                        response.data.message !== ""
                                    ) {
                                        commonAct.showError(
                                            response.data.message || this.$lang.common.error
                                        );
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                commonAct.showError(error);
                            });
                    }
                );
            },
            saveSettingsUsers() {
                usersApi.saveSettingsUsers({
                    ...this.settings,
                    userId: this.detailUserItem.id,
                    deviceInformation: JSON.stringify(this.getInformationClient()),
                })
                .then((response) => {
                    if (response.data && response.data.result === 0) {
                        this.showSuccessToast("User settings has been updated");
                        this.getUserDetail();
                    }
                    else {
                        this.getUserDetail();
                    }
                })
                .catch((error) => {
                    this.getUserDetail();
                    console.error(error);
                });
            }
        },
    };
</script>

<style>
    .socket-client-card .el-card__body {
        padding: 0.5rem 0.8rem !important;
    }
    .deactive-account {
        filter: grayscale();
    }
</style>