import usersApi from '@/api/common/users';
const getlazyLoadInfo = (instance, element, userId) => {
    usersApi.getlazyLoadInfo(userId).then((res) => {
        try {
            //console.log('getlazyLoadInfo', res);
            if (res.data.result === 0) {
                instance.$set(element, 'isEmailSpam', res.data.data.isEmailSpam);
                instance.$set(element, 'gpuHubAccountBalance', res.data.data.gpuHubAccountBalance);
                instance.$set(element, 'gpuHubExtraBalance', res.data.data.gpuHubExtraBalance);
                instance.$set(element, 'totaL_RECHARGE_BAL1', res.data.data.totaL_RECHARGE_BAL1);
                instance.$set(element, 'totaL_RECHARGE_BAL2', res.data.data.totaL_RECHARGE_BAL2);
                instance.$set(element, 'totaL_RECHARGE_BAL', res.data.data.totaL_RECHARGE_BAL);
                instance.$set(element, 'referalUserName', res.data.data.referalUserName);
                instance.$set(element, 'referalUserId', res.data.data.referalUserId);
                instance.$set(element, 'isSystemUser', res.data.data.isSystemUser);
                instance.$set(element, 'userRole', res.data.data.userRole);
                instance.$set(element, 'userType', res.data.data.userType);
                instance.$set(element, 'countNote', res.data.data.countNote);
                instance.$set(element, 'isUsingDesktopApp', res.data.data.isUsingDesktopApp);
                if (res.data.data.partnerInfo) {
                    try {
                        instance.$set(element, 'partnerInfo', JSON.parse(res.data.data.partnerInfo));
                    }
                    catch (error) {
                        console.error(error);
                        instance.$set(element, 'partnerInfo', null);
                    }
                }
                else {
                    instance.$set(element, 'partnerInfo', null);
                }
            }
        } catch (error) {
            console.log("getlazyLoadInfo -> error", error);
        }
    }).catch(error => {
        console.log("getlazyLoadInfo -> error", error);
    });
};

export { getlazyLoadInfo };