import { agencyCnf } from '@/constant/config.js';
import axios from "axios";
export default {
    enableAgency(data) {
        return axios({
            method: "POST",
            url: agencyCnf.enableAgency,
            data: data,
            withCredentials: true,
        });
    }
};
